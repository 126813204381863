<template>
  <div>
    <!-- Moderators -->
    <div class="d-flex justify-space-between mb-3">
      <v-subheader class="px-0 body-1">Moderators</v-subheader>

      <v-btn
        text
        depressed
        outlined
        color="green darken-2"
        class="mb-3 mt-1"
        small
        @click="addingMod = !addingMod"
      >
        {{ addingMod ? 'Cancel' : 'Add Mod' }}
      </v-btn>
    </div>

    <template v-if="addingMod">
      <template v-if="!selectedUser">
        <v-menu
          offset-y
          auto
          max-height="250px"
          content-class="white"
          v-bind:style="`border-bottom: solid 2px ${mainColor};`"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="username"
              @input="findMod"
              v-on="on"
              type="text"
              v-bind="attrs"
              name="mod"
              hide-details
              dense
              outlined
              :loading="searching"
              :color="mainColor"
            >
              <template v-slot:append-outer>
                <v-progress-circular
                  v-if="searching"
                  :width="2"
                  :size="20"
                  color="green darken-2"
                  indeterminate
                ></v-progress-circular>
              </template>
            </v-text-field>
          </template>

          <div
            v-if="searching"
            class="text-center d-flex align-center justify-center p-2"
            style="height: 100%; width: 100%"
          >
            <v-progress-circular
              :width="3"
              :size="60"
              :color="mainColor"
              indeterminate
            ></v-progress-circular>
          </div>

          <v-card-subtitle v-if="!searching && users.length == 0"
            >Found no one :/</v-card-subtitle
          >

          <template v-if="!searching && users.length > 0">
            <v-card-subtitle class="text-center"
              >Found {{ users.length }} users
            </v-card-subtitle>
            <v-list color="white" max-height="250px">
              <v-list-item
                color="white"
                v-for="(user, i) in users"
                link
                @click="addMod(user)"
                :key="i"
              >
                <v-list-item-title>@{{ user }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </v-menu>
      </template>

      <template v-else>
        <v-card :loading="addingModLoading" outlined class="pa-2 my-2">
          <v-card-title>
            Add &nbsp;
            <v-chip small>{{ selectedUser }}</v-chip> as a moderator
          </v-card-title>
          <v-card-actions>
            <v-btn
              :disabled="addingModLoading"
              color="success"
              @click="submitMod"
              depressed
            >
              Add
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              color="error"
              :disabled="addingModLoading"
              depressed
              @click="selectedUser = ''"
            >
              Fashi
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </template>

    <template v-if="allMods.length > 0">
      <v-list>
        <v-list-item class="pa-0" v-for="(moderator, i) in allMods" :key="i">
          <b class="font-weight-bold mr-4">
            {{ i + 1 }}
          </b>

          <v-list-item-title>
            {{ moderator }}
            <v-icon
              small
              color="yellow darken-2"
              v-if="moderator == super_moderator"
            >
              {{ mdiCrown }}
            </v-icon>
          </v-list-item-title>

          <!-- Actions -->

          <v-list-item-action>
            <template v-if="!removingModLoading">
              <v-btn
                @click="removeMod(moderator)"
                v-if="moderator !== super_moderator"
                icon
              >
                <v-icon color="red ligthen-1">
                  {{ mdiClose }}
                </v-icon>
              </v-btn>
            </template>

            <template v-else>
              <v-progress-circular
                v-if="removingModLoading"
                :width="2"
                :size="20"
                color="red lighten-1"
                indeterminate
              ></v-progress-circular>
            </template>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>

    <template v-else>
      <span>No mods (expect you of course :p)</span>
    </template>
  </div>
</template>
<script>
import http from '@/plugins/http';
// icons
import { mdiClose, mdiCrown } from '@mdi/js';
export default {
  name: 'Mods',
  props: {
    regular_moderators: {
      type: Array
    },
    super_moderator: {
      type: String
    },
    mainColor: {
      type: String
    },
    circle_slug: {
      type: String
    }
  },
  data: function() {
    return {
      mods: this.regular_moderators || [],
      addingMod: false,
      username: '',
      timeout: null,
      searching: false,
      users: [],
      selectedUser: '',
      addingModLoading: false,
      removingModLoading: false,
      modAdded: false,
      removedMod: '',
      // icons
      mdiClose,
      mdiCrown
    };
  },
  computed: {
    allMods: function() {
      return [this.super_moderator, ...this.regular_moderators];
    }
  },
  methods: {
    findMod: function(name) {
      // Clear the timeout if it has already been set.
      // This will prevent the previous task from executing
      // if it has been less than <MILLISECONDS>

      if (name == '' || name == ' ') {
        return;
      }

      clearTimeout(this.timeout);
      let self = this;

      // Make a new timeout set to go off in 1000ms (1 second)
      this.timeout = setTimeout(function() {
        console.log('Input Value:', name);
        self.find(name);
      }, 1000);
    },
    find: function(username) {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };

      this.searching = true;

      http
        .get(`/v1/users-search/?username=${username}`, {
          headers
        })
        .then(response => {
          console.log('User =>', response.data.users);
          console.log(this.allMods.includes(username));

          this.users = response.data.users.filter(u => {
            return !this.allMods.includes(u);
          });

          //   this.users = response.data.users;
        })
        .catch(error => {
          console.log('Error =>', error);
        })
        .finally(() => {
          this.searching = false;
        });
    },
    addMod(username) {
      console.log('user => ', username);
      this.selectedUser = username;
    },
    submitMod() {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };

      this.addingModLoading = true;

      http
        .post(
          `/v1/circle/${this.circle_slug}/moderator/${this.selectedUser}/`,
          {
            data: 'yeet'
          },
          {
            headers
          }
        )
        .then(response => {
          if (response.data.success) {
            console.log('Response =>', response.data);

            this.addingMod = false;

            // this.allMods.push(this.selectedUser);

            this.$emit('add-mod', this.selectedUser);

            this.selectedUser = '';

            this.$store.dispatch('SHOW_TOAST', {
              message: response.data.message,
              style: 'success'
            });
          } else {
            this.$store.dispatch('SHOW_TOAST', {
              message: response.data.message,
              style: 'success'
            });
          }
        })
        .catch(error => {
          console.log('Error =>', error);

          this.$store.dispatch('SHOW_TOAST', {
            message: "Could'nt add that moderator. Please try again :/",
            style: 'error'
          });
        })
        .finally(() => {
          this.addingModLoading = false;
        });
    },
    removeMod(user) {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };

      const ans = window.confirm(`Sure you want to remove ${user} as a mod?`);

      if (!ans) {
        return;
      }

      this.removingModLoading = true;

      http
        .delete(`/v1/circle/${this.circle_slug}/moderator/${user}/`, {
          headers
        })
        .then(response => {
          if (response.data.success) {
            console.log('Response =>', response.data);

            this.removingModLoading = false;

            // this.allMods.splice(1, this.allMods.indexOf(name));

            this.$emit('remove-mod', user);

            this.removedMod = '';

            this.$store.dispatch('SHOW_TOAST', {
              message: response.data.message,
              style: 'success'
            });
          } else {
            this.$store.dispatch('SHOW_TOAST', {
              message: response.data.message,
              style: 'success'
            });
          }
        })
        .catch(error => {
          console.log('Error =>', error);
          this.$store.dispatch('SHOW_TOAST', {
            message: `Couldn't remove that mod. Please try again later :/`,
            style: 'error'
          });
        })
        .finally(() => {
          this.removingModLoading = false;
        });
    }
  }
};
</script>
<style scoped></style>
