<template>
  <v-dialog :value="show" @input="$emit('update:show', $event)" width="500">
    <v-card class="pa-0">
      <v-card-title class="headline grey lighten-2" primary-title>
        Pick a color plis :)
      </v-card-title>

      <v-color-picker
        class="ma-0"
        :swatches="swatches"
        show-swatches
        :value="color"
        @input="$emit('update:themeColor', $event)"
        flat
      ></v-color-picker>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ColorPicker',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    themeColor: {
      type: String
    }
  },
  data() {
    return {
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055']
      ],
      dialog: this.show,
      color: this.themeColor
    };
  },
  methods: {
    changeColor() {
      this.$emit('update:themeColor', this.color);
      console.oog(this.color);
    }
  }
};
</script>

<style></style>
