<template>
  <div>
    <template v-if="loading">
      <v-skeleton-loader
        v-for="(n, i) in 7"
        :loading="loading"
        :key="i"
        height="400px"
        type="card"
      ></v-skeleton-loader>
    </template>

    <div v-show="!loading && feed.length > 0" style="margin-bottom: 55px">
      <post
        v-for="(post, i) in feed"
        :key="i"
        :index="i"
        :post="post"
        :insideViewPost="false"
        :circleColor="mainColor"
      ></post>
    </div>
    <div
      class="d-flex text-center justify-center align-center"
      v-if="feed.length == 0 && !loading"
      style="height: 500px"
    >
      <div class="text-center">
        <img class="py-3 empty-folder" src="/img/icons/empty-folder.png" />
        <span
          class="subtitle-2 d-block text-light lighten mt-5 mb-2"
          style="width: 200px"
          >No Posts in ~{{ $route.params['circle_slug'] }}</span
        >
        <p></p>
      </div>
    </div>
  </div>
</template>

<script>
import Post from '@/components/post/post.vue';
export default {
  name: 'CirclePosts',
  components: {
    Post
  },
  props: {
    feed: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    mainColor: {
      type: String
    }
  }
};
</script>

<style scoped></style>
