export default {
  data: function() {
    return {
      fromRoute: ''
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRoute = from.name;
    });
  },
  methods: {
    back() {
      if (this.fromRoute) {
        this.$router.back();
      } else {
        // Go back to the feed doe
        // TODO: change this to accept parameter for the routes...
        this.$router.push({ name: 'Feed' });
      }
    }
  }
};
