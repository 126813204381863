<template>
  <div>
    <slot
      v-bind:editing="editing"
      v-bind:changeEditing="changeEditing"
      v-bind:doneEditing="doneEditing"
    ></slot>
  </div>
</template>

<script>
export default {
  name: 'rule',
  props: {
    rule: {
      type: Object
    }
  },
  data() {
    return {
      editing: false,
      ruleText: this.rule.text || ''
    };
  },
  methods: {
    changeEditing() {
      console.log('hi :)');
      this.editing = true;
    },
    doneEditing(rule) {
      this.editing = false;

      if (this.ruleText == rule) {
        console.log('same');
        return;
      }

      console.log('different and done!');
      this.$emit('changed-rule');
    }
  }
};
</script>

<style></style>
