<template>
  <div id="circle" class="overflow-y-auto">
    <v-app-bar
      dark
      fixed
      app
      :color="!showSearchBar ? styles.mainColor : 'secondary'"
      fade-img-on-scroll
      scroll-threshold="40"
      extension-height="auto"
      :height="!showSearchBar ? circleBarHeight : '56px'"
      shrink-on-scroll
      tile
      style="z-index: 6 !important;"
      prominent
      :src="
        circle.banner ||
          'https://i.ibb.co/s9YGcYP/edited-sharon-mccutcheon-TZZw-C-xs-Cl-Y-unsplash.jpg'
      "
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          :hidden="showSearchBar"
          gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6)"
        ></v-img>
      </template>

      <v-btn icon @click="!showSearchBar ? back() : closeSearchBar()">
        <v-icon color="white"> {{ mdiArrowLeft }} </v-icon>
      </v-btn>

      <v-scroll-x-reverse-transition>
        <v-toolbar-title
          v-if="!showSearchBar"
          class="text-capitalize pl-0 d-flex align-center"
        >
          <template v-if="loading">
            <v-skeleton-loader
              :loading="loading"
              width="180px"
              type="text"
            ></v-skeleton-loader>
          </template>
          <template v-else>
            <v-avatar v-if="circle.display_picture" color="white" size="35">
              <v-img width="35" height="35" :src="circle.display_picture">
              </v-img>
            </v-avatar>
            <span class="pl-2" style="font-size: 1.2rem !important"
              >{{ $store.getters.circle.slug }}
            </span>
          </template>
        </v-toolbar-title>
      </v-scroll-x-reverse-transition>

      <v-expand-x-transition>
        <v-text-field
          v-show="showSearchBar"
          single-line
          background-color="transparent"
          :hide-details="true"
          style="width: 100%;"
          label="Search"
          autofocus
          required
          :loading="searchLoading"
          @keydown.enter="search"
          @input="searchStarted = false"
          v-model="query"
          type="text"
          class="mt-1"
        >
        </v-text-field>
      </v-expand-x-transition>

      <v-spacer></v-spacer>

      <v-btn
        icon
        color="white"
        v-if="!searchStarted"
        @click="!showSearchBar ? (showSearchBar = true) : search()"
      >
        <v-icon color="white"> {{ mdiMagnify }} </v-icon>
      </v-btn>

      <v-btn
        v-if="searchStarted && showSearchBar"
        icon
        color="white"
        @click="clearSearch"
      >
        <v-icon color="white">
          {{ mdiClose }}
        </v-icon>
      </v-btn>

      <v-btn
        icon
        color="white"
        v-if="canWebShare"
        @click="shareCircle('circl-topbar')"
      >
        <v-icon color="white"> {{ mdiShareVariant }} </v-icon>
      </v-btn>
    </v-app-bar>

    <v-card flat>
      <v-card-title class="justify-space-between align-baseline">
        <div>
          <v-btn
            v-if="!$store.getters.belongsToCircle(circle.slug)"
            small
            outlined
            :loading="joinLoading"
            @click.stop="join(circle.id)"
            :color="styles.mainColor"
          >
            <v-icon small :color="styles.mainColor"> {{ mdiPlus }} </v-icon>Join
          </v-btn>
          <v-btn
            v-else
            @click.stop="leave(circle.id)"
            small
            :loading="joinLoading"
            outlined
            class="pl-1"
            color="error"
          >
            <v-icon small color="error"> {{ mdiMinus }} </v-icon>Leave
          </v-btn>
          <!-- <br />
          <span class="subtitle-2"
            >{{ circle.users }}
            {{ circle.users == 1 ? 'Member' : 'Members' }}</span
          > -->
        </div>

        <v-chip
          :color="styles.mainColor"
          v-if="canWebShare"
          @click="shareCircle('circl-details')"
        >
          <span class="font-sen font-weight-bold" v-bind:class="textColor">
            Share Circle
            <v-icon v-bind:class="textColor" small>
              {{ mdiShareVariant }}
            </v-icon>
          </span>
        </v-chip>
      </v-card-title>

      <v-card-subtitle class="flex-row pb-1 mt-0">
        <span class="subtitle-2"
          >{{ circle.users }}
          {{ circle.users == 1 ? 'Member' : 'Members' }}</span
        >
        <div v-if="isRegularModerator">
          You can stop being a mod at any time
          <v-btn
            depressed
            class="ml-auto"
            @click="removeMod"
            :loading="removingModLoading"
            >Oya</v-btn
          >
        </div>
      </v-card-subtitle>
    </v-card>

    <v-divider></v-divider>
    <div class="container pa-0">
      <v-tabs v-model="tabs" fixed-tabs class="px-3" :color="styles.mainColor">
        <v-tabs-slider :color="styles.mainColor"></v-tabs-slider>
        <v-tab :color="styles.mainColor" to="#posts" :replace="true"
          >Posts</v-tab
        >

        <v-tab :color="styles.mainColor" to="#about" :replace="true"
          >About</v-tab
        >

        <v-tab
          v-if="isSuperModerator"
          :color="styles.mainColor"
          to="#edit"
          :replace="true"
          ><v-icon :color="styles.mainColor" small>
            {{ mdiPencil }}
          </v-icon></v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tabs">
        <v-tab-item value="posts">
          <posts-tab :feed="feed" :mainColor="styles.mainColor"></posts-tab>

          <template v-if="moreLoadingBottom">
            <div class="d-flex my-4 pa-3 justify-center align-center">
              <v-progress-circular
                :width="3"
                :size="40"
                color="primary lighten-1"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>
        </v-tab-item>
        <v-tab-item value="about">
          <details-tab
            :description="circle.description"
            :rules="circle.rules"
            :super_moderator="circle.super_moderator"
            :regular_moderators="circle.regular_moderators"
            :date_created="circle.date_created"
            :loading="loading"
          ></details-tab>
        </v-tab-item>
        <v-tab-item v-if="isSuperModerator" value="edit">
          <settings-tab
            v-bind:circle="{
              ...circle
            }"
            :mainColor.sync="styles.mainColor"
            @circle-updated="updateCircle"
            @remove-mod="$emit('remove-mod', $event)"
            @add-mod="$emit('add-mod', $event)"
          ></settings-tab>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <v-overlay
      :value="showSearchBar"
      light
      z-index="5"
      opacity="0.8"
      class="d-block"
    >
      <template v-slot:default>
        <div
          id="poop"
          style="position: relative;
    top: 60px;"
        >
          <v-subheader
            v-if="results.length > 0"
            class="white--text d-flex justify-space-between"
            >Search Results
            <span class="ml-3 font-weight-bold"
              >{{ results.length }} Posts</span
            >
          </v-subheader>
          <div id="search-results" class="pb-12">
            <v-list
              v-if="results.length > 0"
              style="background-color: transparent"
              class="px-2"
            >
              <template v-for="(post, i) in results">
                <v-list-item
                  :to="`/c/${post.circle}/p/${post.slug}`"
                  link
                  :key="post.id"
                  class="mb-1"
                >
                  <v-list-item-avatar
                    color="transparent"
                    class="body font-weight-bold"
                    tile
                  >
                    <template v-slot:default>
                      <div class="d-flex flex-column">
                        <span class="white--text">
                          {{ (upvotes = post.upvotes + post.downvotes) }}
                        </span>
                        <span class="caption grey--text">
                          {{ upvotes > 1 ? 'upvotes' : 'upvote' }}
                        </span>
                      </div>
                    </template>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title> {{ post.caption }} </v-list-item-title>
                    <v-list-item-subtitle class="caption"
                      >{{ post.duration }} ago</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-list-item-avatar tile>
                    <v-img
                      :src="post.image"
                      height="60"
                      width="60"
                      :lazy-src="`https://picsum.photos/10/6`"
                    >
                    </v-img>
                  </v-list-item-avatar>
                </v-list-item>

                <v-divider :key="i + '-d'"></v-divider>
              </template>
            </v-list>
            <div
              v-else-if="initialSearch && results.length == 0"
              class="d-flex text-center justify-center align-center"
              style="height: 300px"
            >
              <div class="text-center">
                <span
                  class="d-block white--text text-light mt-5 mb-2"
                  style="width: 200px"
                  >No results ye!</span
                >
                <p class="caption white--text text--lighten-2">
                  Search for something else :p
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-overlay>
  </div>
</template>

<script>
import http from '@/plugins/http';
import { DetailsTab, PostsTab, SettingsTab } from './tabs';
import { lightordark, ignoreColors } from '@/utils/colors';
import mainColor from 'rgbaster';
import backer from '@/mixins/router-back';
// icons
import {
  mdiShareVariant,
  mdiPencil,
  mdiPlus,
  mdiMinus,
  mdiMagnify,
  mdiArrowLeft,
  mdiClose
} from '@mdi/js';

export default {
  name: 'ViewCircle',
  mixins: [backer],
  components: {
    DetailsTab,
    PostsTab,
    SettingsTab
  },
  props: {
    circle: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: true,
    searchLoading: false,
    joinLoading: false,
    scrollHeight: 0,
    circleBarHeight: '105',
    tabs: 1,
    tab: 1,
    showSearchBar: false,
    searchStarted: false,
    initialSearch: false,
    query: '',
    results: {
      posts: []
    },
    /** ===== Bottom Loader and shii */
    feed: [],
    moreLoadingBottom: false,
    atBottom: false,
    bottom: false,
    next_page: null,
    styles: {
      mainColor: 'primary'
    },
    /** remove mod */
    removingModLoading: false,
    // icons
    mdiShareVariant,
    mdiPencil,
    mdiPlus,
    mdiMinus,
    mdiMagnify,
    mdiArrowLeft,
    mdiClose
  }),
  computed: {
    loggedIn: function() {
      return this.$store.getters.loggedIn;
    },
    isMaestro() {
      if (!this.$store.getters.user) {
        return false;
      }
      let user = JSON.parse(window.localStorage.getItem('bubbl_user'));

      return user.badges.some(badge => {
        return 'maestro' == badge.badge.name.trim().toLowerCase();
      });
    },

    username: function() {
      if (this.loggedIn) {
        return this.$store.getters.user.username;
      }

      return false;
    },

    mainColorBrightness() {
      if (this.styles.mainColor) {
        return lightordark(this.styles.mainColor);
      }

      return 'dark';
    },

    textColor: function() {
      return {
        'white--text': this.mainColorBrightness == 'dark',
        'black--text': this.mainColorBrightness == 'light'
      };
    },
    isSuperModerator: function() {
      if (!this.loggedIn) {
        return false;
      }

      return this.circle.super_moderator == this.username;
    },
    isRegularModerator: function() {
      if (this.loggedIn) {
        if (this.circle.regular_moderators) {
          return this.circle.regular_moderators.includes(this.username);
        }
      }
      return false;
    },
    canWebShare: function() {
      return navigator.share;
    }
  },
  watch: {
    circle: function() {
      // TODO: Find a BETTER WAY!
      this.getStyles();
    },
    bottom: function(bottom) {
      if (bottom) {
        this.fetcherNext();
      }
    }
  },
  methods: {
    join() {
      if (!this.loggedIn) {
        this.$store.dispatch('SHOW_AUTH_PROMPT', {
          message: 'Yo!'
        });

        return;
      }
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };
      const circleSlug = this.$route.params['circle_slug'];
      this.joinLoading = true;
      http
        .post(`/v1/circle/${circleSlug}/users/`, { data: 'yarns' }, { headers })
        .then(response => {
          console.log('res => ', response.data);
          this.$store.dispatch('JOIN_CIRCLE', this.circle);
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Joined circle successfully',
            style: 'info'
          });
          if (this.$gtag) {
            this.$gtag.event('joined_circle', { event_label: 'Google' });
          }
        })
        .catch(response => {
          console.log('res => ', response.data);
          if (this.$gtag) {
            this.$gtag.event('error_joining_circle', {
              event_label: 'Google'
            });
          }
        })
        .finally(() => {
          this.joinLoading = false;
        });
    },
    leave() {
      if (!this.loggedIn) {
        this.$store.dispatch('SHOW_AUTH_PROMPT', {
          message: 'Yo!'
        });

        return;
      }
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };
      const circleSlug = this.$route.params['circle_slug'];
      this.joinLoading = true;
      http
        .delete(`/v1/circle/${circleSlug}/users/`, { headers })
        .then(response => {
          console.log('res => ', response.data);
          this.$router.push({ name: 'Feed' });
        })
        .catch(response => {
          console.log('res => ', response.data);
        })
        .finally(() => {
          this.joinLoading = false;
        });

      this.$store.dispatch('LEAVE_CIRCLE', this.circle.name);
      this.$store.dispatch('SHOW_TOAST', {
        message: 'Left circle successfully',
        style: 'info'
      });
    },
    getFeed(circleSlug, page = 1) {
      // this.$store.dispatch('TOGGLE_LOADER');
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      let headers = {
        Authorization: `token ${token}`
      };
      // const circleSlug = this.$route.params['circle_slug'];

      if (!token) {
        headers = {};
      }
      this.moreLoadingBottom = true;
      http
        .get(`/v1/circle/${circleSlug}/feed/?page=${page}`, { headers })
        .then(response => {
          this.feed.push(...response.data.results);
          this.next_page = response.data.next
            ? parseInt(
                response.data.next
                  .split('page')
                  .join('')
                  .split('=')[1]
              )
            : null;
          this.atBottom = false;
          this.moreLoadingBottom = false;
          this.bottom = false;
        })
        .catch(response => {
          this.$store.dispatch('SHOW_TOAST', {
            message: "Couldn't fetch feed, please try again",
            style: 'error'
          });
          this.atBottom = false;
          this.moreLoadingBottom = false;
          this.bottom = false;
          console.log('Error ! =>', response);
        })
        .finally(() => {
          // this.$store.dispatch('TOGGLE_LOADER');
          this.loading = false;
        });
    },
    getCircle(circleSlug) {
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      let headers = {
        Authorization: `token ${token}`
      };
      // const circleSlug = this.$route.params['circle_slug'];

      if (!token) {
        headers = {};
      }

      http
        .get(`/v1/circle/${circleSlug}/`, { headers })
        .then(response => {
          this.circle = response.data;
          this.$store.dispatch('SET_CIRCLE', response.data);
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.$router.push({
              name: '404',
              query: { lost: 'circle' },
              params: { '0': this.$route.path }
            });
            return;
          }
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Error getting circle, please try again :(',
            style: 'error'
          });
          console.log('Error ! =>', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      if (this.query != '') {
        this.searchStarted = true;
        this.searchLoading = true;
        const token =
          this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
        let headers = {
          Authorization: `token ${token}`
        };

        if (!token) {
          headers = {};
        }
        const circleSlug = this.$route.params['circle_slug'];
        http
          .get(`/v1/circle/${circleSlug}/search/?search=${this.query}`, {
            headers
          })
          .then(response => {
            // TODO: make this support pagination...
            this.results = response.data.results;
            console.log('Search completed successfully', response.data);
          })
          .catch(response => {
            this.$store.dispatch('SHOW_TOAST', {
              message: "Couldn't make search, please try again :(",
              style: 'error'
            });
            console.log('Error searching query ! =>', response);
          })
          .finally(() => {
            this.searchLoading = false;
            this.initialSearch = true;
          });
      } else {
        window.alert('search cannot be empty ^.^');
      }
    },
    clearSearch() {
      this.searchStarted = false;

      this.results = {
        posts: []
      };

      this.query = '';
    },
    closeSearchBar() {
      this.searchStarted = false;
      this.showSearchBar = false;
    },
    updateCircle(updatedCircle) {
      this.$emit('update-circle', updatedCircle);
    },
    async getMainColor(banner) {
      let bg_url =
        banner ||
        'https://i.ibb.co/s9YGcYP/edited-sharon-mccutcheon-TZZw-C-xs-Cl-Y-unsplash.jpg';

      let proxyUrl = 'https://proxy.mybubbl.me/';

      let result = await mainColor(proxyUrl + bg_url, {
        scale: 0.5,
        ignore: ignoreColors
      });

      // var base64 = getBase64Image(document.getElementById("imageid"));
      this.styles.mainColor = result[0].color;
    },
    // Maybe yoou might need this knowledge later <3
    loadImgAsBase64(url, callback) {
      let canvas = document.createElement('CANVAS');
      let img = new window.Image();
      img.setAttribute('crossorigin', 'anonymous');
      img.src = url;

      img.onload = () => {
        canvas.height = img.height;
        canvas.width = img.width;
        let context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL('image/png');
        canvas = null;
        callback(dataURL);
      };
    },
    getStyles() {
      if (this.circle.styles) {
        this.styles = JSON.parse(this.circle.styles);
      } else {
        this.styles = { mainColor: 'primary' };
      }
    },
    scroller() {
      // let bottomOfWindow =
      //   document.documentElement.scrollTop + window.innerHeight >=
      //   document.documentElement.offsetHeight - 50;

      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      let bottomOfWindow = bottomOfPage || pageHeight < visible;

      if (bottomOfWindow && !this.atBottom) {
        this.bottom = true;
        return;
      }
    },
    fetcherNext: function() {
      this.atBottom = true;
      this.moreLoadingBottom = true;
      this.postsHafFinish = false;

      if (this.next_page == null) {
        console.log('posts haf finished :)');
        this.moreLoadingBottom = false;
        this.postsHafFinish = true;
        return;
      }

      this.getFeed(this.circle.slug, this.next_page);
    },
    removeMod() {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };

      const ans = window.confirm(`Sure you want to stop being a mod?`);

      if (!ans) {
        return;
      }

      if (!this.loggedIn) {
        return;
      }

      this.removingModLoading = true;

      http
        .delete(`/v1/circle/${this.circle.slug}/moderator/${this.username}/`, {
          headers
        })
        .then(response => {
          if (response.data.success) {
            console.log('Response =>', response.data);

            this.$emit('remove-mod', this.username);

            this.$store.dispatch('SHOW_TOAST', {
              message: 'You are no longer a moderator! :)',
              style: 'success'
            });
          } else {
            this.$store.dispatch('SHOW_TOAST', {
              message: response.data.message,
              style: 'success'
            });
          }
        })
        .catch(error => {
          console.log('Error =>', error);
          this.$store.dispatch('SHOW_TOAST', {
            message: `Couldn't remove that mod. Please try again later :/`,
            style: 'error'
          });
        })
        .finally(() => {
          this.removingModLoading = false;
        });
    },
    shareCircle(source) {
      if (navigator.share) {
        if (this.$gtag) {
          this.$gtag.event(source, {
            event_label: 'Share Circle Button Clicked'
          });
        }

        navigator
          .share({
            title: `Hey, I think you'll like this Circle`,
            text: `Check out ${this.circle.name} on Bubbl!`,
            url: `https://link.mybubbl.me/c/${this.circle.slug}`
          })
          .then(() => console.log('Sharing modal opened succesfully!'))
          .catch(error => console.log('Error opening Sharing modal', error));
      } else {
        alert('Sorry this feature is not supported on your browser :(');
      }
    }
  },
  mounted() {
    const circleSlug = this.$route.params['circle_slug'];
    // this.getCircle(circleSlug);
    this.getFeed(circleSlug);
    this.getStyles();
    window.addEventListener('scroll', this.scroller);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroller);
  },
  head() {
    return {
      title: `See posts in c/${this.circle.slug}`
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (!to.params.circle_slug == from.params.circle_slug) {
      this.$emit('view-another-circle', to.params.circle_slug);
    }

    this.getFeed(to.params.circle_slug);
    // this.getMainColor();
    next();
  }
};
</script>

<style scoped>
/* .v-toolbar__content {
  display: flex;
  flex-direction: row;
} */

.v-overlay__content {
  display: block !important;
  height: 83% !important;
  top: 10px !important;
  width: 93% !important;
}

#search-results {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
}
</style>
