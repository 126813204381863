export function lightordark(color) {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp < 100 || hsp > 1000) {
    return 'dark';
  } else {
    return 'light';
  }
}
// From https://awik.io/determine-color-bright-dark-using-javascript/ (6 June 2020)

export const ignoreColors = [
  'rgb(255,255,255)',
  'rgb(0,0,0)',
  'rgb(255,250,250)',
  'rgb(240,255,240)',
  'rgb(245,255,250)',
  'rgb(248,248,255)',
  'rgb(255,255,240)',
  'rgb(255,240,245)',
  'rgb(254,254,254)'
];
