<template>
  <div>
    <v-card
      :class="card.class"
      ripple
      @click="uploadImage"
      flat
      :height="card.height"
      :style="card.style"
      :width="card.width"
    >
      <!-- class: mt-3 mx-auto d-flex justify-center -->
      <v-sheet
        v-if="!uploadedImage"
        :class="cardSheet.class"
        color="secondary lighten-3"
        :style="cardSheet.style"
        :height="cardSheet.height"
        :width="cardSheet.width"
      >
        <div class="text-center mx-auto">
          <v-icon color="white">
            {{ mdiCamera }}
          </v-icon>
          <!-- <p class="body mt-2 white--text">
                    Circle Avatar
                  </p> -->
        </div>
      </v-sheet>

      <!-- Put this instead of Sheet when an image is uploaded :) -->

      <v-img
        v-else
        :src="uploadedImage"
        :contain="previewImage.contain"
        :height="previewImage.height"
        :width="previewImage.width"
        :style="previewImage.style"
      ></v-img>
      <!-- style: border-radius: 50px -->
    </v-card>

    <!-- Native Image input field -->

    <v-file-input
      accept="image/png, image/jpeg, image/gif, image/bmp"
      placeholder="Pick an image"
      :prepend-icon="mdiImage"
      ref="imageUploader"
      class="d-none"
      @change="imageUploaded"
      label="Photo"
    >
      <template v-slot:default>
        <v-btn>Select file!</v-btn>
      </template>
    </v-file-input>
  </div>
</template>

<script>
// import { compress } from 'image-conversion';
// icons
import { mdiImage, mdiCamera } from '@mdi/js';
export default {
  name: 'ImageUploader',
  props: {
    previewImage: {
      type: Object,
      default: function() {
        return {
          height: '',
          width: '',
          style: '',
          src: '',
          contain: false
        };
      }
    },
    cardSheet: {
      type: Object,
      default: function() {
        return {
          height: '100px',
          width: '100px',
          class: '',
          style: ''
        };
      }
    },
    card: {
      type: Object,
      default: function() {
        return {
          height: '100px',
          width: '100px',
          class: '',
          style: ''
        };
      }
    },
    image: {
      default: ''
    },
    compress: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      uploadedImage: this.previewImage.src || '',
      mdiCamera,
      mdiImage
    };
  },
  methods: {
    uploadImage() {
      this.$refs.imageUploader.$refs.input.click();
    },
    async imageUploaded(file) {
      if (file) {
        //   this.image = file;

        let reader = new FileReader();

        reader.addEventListener('load', async e => {
          this.uploadedImage = e.target.result;
          this.$emit('uploaded-image-src', this.uploadedImage);

          // reader.readAsDataURL(file);

          // this.$emit('update:image', image);
        });

        reader.readAsDataURL(file);
        this.$emit('update:image', file);

        // TODO: add it back but only for image posts.

        // const image = await compressAccurately(file, 500);

        // const image = await compress(file, 0.5);

        // console.log('Image =>', image);

        // this.$emit('update:image', image);
      }
    },
    blobToImage() {}
  }
};
</script>

<style></style>
