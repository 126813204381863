<template>
  <div>
    <v-card-text>
      <!-- Show circle name here... -->
      <v-subheader class="px-0 body-1">About</v-subheader>

      <v-textarea
        v-model="description"
        auto-grow
        outlined
        rows="3"
        type="text"
        :rules="[validation.required]"
        :disabled="loading"
        hint="What's this circle about i.e 'Pictures of Goats'"
        :color="mainColor"
      ></v-textarea>

      <mods
        :regular_moderators="circle.regular_moderators"
        :super_moderator="circle.super_moderator"
        :mainColor="mainColor"
        :circle_slug="circle.slug"
        @remove-mod="$emit('remove-mod', $event)"
        @add-mod="$emit('add-mod', $event)"
      ></mods>

      <!-- Rules -->
      <v-subheader class="px-0 body-1">Rules</v-subheader>
      <p class="mb-1 caption grey--text">
        For example 'Cute goats only!'
      </p>
      <template v-for="(rule, id) in rules">
        <rule :rule="rules[id]" :key="id" @changed-rule="rulesChanged = true">
          <template v-slot:default="{ editing, changeEditing, doneEditing }">
            <!-- TODO: prevent the enter button from working when this is readonly -->
            <v-text-field
              @keydown.enter="!editing ? null : addRule()"
              v-model="rules[id].text"
              :readonly="!editing"
              type="text"
              hide-details
              @click="rules[id].emptyError = false"
              autofocus
              dense
              outlined
              :error="rules[id].emptyError"
              :color="mainColor"
            >
              <template v-slot:append-outer>
                <v-btn v-if="!editing" @click="changeEditing" icon>
                  <v-icon color="success lighten-2">
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>

                <v-btn v-if="editing" @click="doneEditing(rules[id].text)" icon>
                  <v-icon color="indigo darken-2">
                    {{ mdiCheck }}
                  </v-icon>
                </v-btn>
                <v-btn @click="deleteRule(id)" icon :disabled="id == 0">
                  <v-icon color="red lighten-2" :disabled="id == 0">
                    {{ mdiClose }}
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </template>
        </rule>
      </template>

      <v-btn
        text
        depressed
        outlined
        color="primary darken-2"
        class="mb-3 mt-1"
        small
        @click="addRule"
        >Add Rule</v-btn
      >

      <!-- Circle theme color picker  -->
      <v-subheader class="px-0 body-1">Circle theme color</v-subheader>
      <v-card height="60px" width="400px" flat>
        <v-sheet width="100%" height="100%" :color="mainColor"></v-sheet>
        <v-overlay absolute opacity="0">
          <v-btn outlined color="white" @click="openColorPicker = true">
            Change
            <v-icon>
              {{ mdiPalette }}
            </v-icon>
          </v-btn>
        </v-overlay>
      </v-card>
      <v-card-subtitle class="caption pl-0"
        >Please don't use light colors :pray:</v-card-subtitle
      >
      <!-- Circle theme color picker -->

      <!-- Display picture and Banner -->
      <!-- Avatar -->
      <v-subheader class="px-0 body-1">Circle Avatar</v-subheader>

      <image-uploader
        v-bind:previewImage="{
          style: 'border-radius: 50px',
          height: '100px',
          width: '100px',
          contain: true,
          src: circle.display_picture
        }"
        v-bind:cardSheet="{
          style: 'border-radius: 50px',
          class: 'd-flex align-center',
          height: '100px',
          width: '100px'
        }"
        v-bind:card="{
          class: 'mt-3 mx-auto d-flex justify-center',
          width: '100px',
          height: '100px'
        }"
        v-bind:image.sync="display_picture"
      ></image-uploader>

      <p class="mb-0 caption grey--text text-center mt-2">200 x 200 abeg</p>

      <!-- Banner -->

      <v-subheader class="px-0 body-1">Circle Banner</v-subheader>

      <image-uploader
        v-bind:previewImage="{
          height: '130px',
          width: '100%',
          contain: false,
          src: circle.banner
        }"
        v-bind:cardSheet="{
          class: 'd-flex align-center',
          height: '130px',
          width: '100%'
        }"
        v-bind:card="{
          class: 'mt-3',
          width: '100%',
          height: '130px'
        }"
        v-bind:image.sync="banner"
        @uploaded-image-src="handleBanner"
      ></image-uploader>

      <p class="caption grey--text text-center mt-11">
        iono tbh, wide sha :)
      </p>

      <!-- proceed with caustion  -->
      <!-- TODO: only allow the super-moderator to be able to open this Edit tab and do stuff... -->
      <!-- Add another confirmation tool before user deletes the circle... -->
      <!-- TODO: I think we should prevent super-mods from deleting their circles after a ceratin stage -->
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header disable-icon-rotate>
            <span class="error--text">Dangerous sturves :o</span>
            <template v-slot:actions>
              <v-icon color="error"> {{ mdiAlertCircle }} </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="body-1 secondary--text">Delete circle</p>
            <p class="caption secondary--text text--lighten-1">
              This is one chance o, cannot be reversed
            </p>
            <v-btn @click="deleteCircle" color="error" depressed>Delete</v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="d-flex justify-space-between">
      <v-btn color="color-purple purple-outline" disabled>
        Back
      </v-btn>

      <v-btn
        depressed
        dark
        :disabled="
          !descriptionChanged &&
            !rulesChanged &&
            !displayPictureChanged &&
            !bannerChanged &&
            !stylesChanged
        "
        color="amber"
        @click="update"
      >
        Update
      </v-btn>
    </v-card-actions>

    <color-picker
      :show.sync="openColorPicker"
      :themeColor.sync="styles.mainColor"
    ></color-picker>
  </div>
</template>

<script>
import http from '@/plugins/http';
import imageUploader from '@/components/helpers/image-uploader.vue';
import rule from '@/components/circles/rule.vue';
import mods from '@/components/circles/mods.vue';
import colorPicker from '@/components/helpers/color-picker.vue';
import { ignoreColors } from '@/utils/colors';
import mainColor from 'rgbaster';
// icons
import {
  mdiCheck,
  mdiCancel,
  mdiAlertCircle,
  mdiPalette,
  mdiClose
} from '@mdi/js';
export default {
  name: 'CircleSettings',
  components: {
    imageUploader,
    rule,
    mods,
    colorPicker
  },
  props: {
    circle: {
      type: Object,
      default: function() {
        return {
          description: '',
          display_picture: '',
          banner: '',
          rules: '',
          slug: '',
          name: '',
          super_moderator: '',
          regular_moderators: [],
          mod_username: '',
          // icons
          mdiCheck,
          mdiCancel,
          mdiAlertCircle,
          mdiPalette,
          mdiClose
        };
      }
    },
    mainColor: String
  },
  data: function() {
    return {
      loading: false,
      description: this.circle.description || '',
      display_picture: this.circle.display_picture || '',
      banner: this.circle.banner || '',
      regular_moderators: this.circle.regular_moderators || [],
      super_moderator: this.circle.super_moderator || '',
      rules: [],
      unformattedRules: '',
      validation: {
        required: value => !!value || 'Write somtin na',
        nameMaxLength: value =>
          value.length < 30 || 'Too long, not more than 30 characters :/'
      },
      // TODO: find better way to monitor these changes...
      descriptionChanged: false,
      rulesChanged: false,
      displayPictureChanged: false,
      bannerChanged: false,
      stylesChanged: false,
      styles: {
        mainColor: this.mainColor
      },
      // ---- ADDING MOD ---- //
      addingMod: false,
      timeout: null,
      openColorPicker: false
    };
  },

  watch: {
    description: function(newvalue) {
      if (newvalue == this.circle.description) {
        console.log('same');
        this.descriptionChanged = false;
        return;
      }

      this.descriptionChanged = true;
    },
    banner: function() {
      this.bannerChanged = true;
      // this.handleBanner(banner);
    },
    'styles.mainColor': function(color) {
      this.stylesChanged = true;
      this.$emit('update:mainColor', color);
    },
    display_picture: function() {
      this.displayPictureChanged = true;
    }
  },
  methods: {
    deleteRule(id) {
      this.rules.splice(id, 1);
    },
    prepareRules() {
      return this.rules
        .map(r => {
          return r.text;
        })
        .join('\n');
    },
    addRule() {
      if (this.rules[this.rules.length - 1].text == '') {
        this.rules[this.rules.length - 1].emptyError = true;
        return;
      }
      this.rules.push({ text: '', emptyError: false });
    },
    update() {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`,
        'Content-Type': 'multipart/form-data'
      };

      let formData = new FormData();

      if (this.descriptionChanged) {
        formData.append('description', this.description);
      }

      if (this.rulesChanged) {
        let unformattedRules = this.prepareRules();
        formData.append('rules', unformattedRules);
      }

      if (this.bannerChanged) {
        formData.append('banner', this.banner);
        formData.append('styles', JSON.stringify(this.styles));
      }

      if (this.stylesChanged) {
        formData.append('styles', JSON.stringify(this.styles));
      }

      if (this.displayPictureChanged) {
        formData.append('display_picture', this.display_picture);
      }

      this.$store.dispatch('TOGGLE_LOADER');

      http
        .patch(`/v1/circle/${this.circle.slug}/update/`, formData, {
          headers
        })
        .then(response => {
          if (response.data.success) {
            // circle was updated successfully :)
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Circle updated successfully :)',
              style: 'success'
            });

            // this.$store.dispatch('FETCH_USER');
            this.descriptionChanged = false;
            this.rulesChanged = false;
            this.displayPictureChanged = false;
            this.bannerChanged = false;

            this.$router.push(`#posts`);
            this.$emit('circle-updated', response.data.data);
          }
        })
        .catch(error => {
          console.log('Error =>', error);
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Something went wrong, please try again',
            style: 'error'
          });
        })
        .finally(() => {
          this.$store.dispatch('TOGGLE_LOADER');
        });
    },
    deleteCircle() {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };

      // TODO: use custom confirm modal abeg :)

      let ans = window.confirm(
        'Are you sure you want to delete \n' + this.circle.name
      );

      if (!ans) {
        return;
      }

      this.$store.dispatch('TOGGLE_LOADER');

      http
        .delete(`/v1/circle/${this.circle.slug}/delete/`, {
          headers
        })
        .then(response => {
          if (response.data.success) {
            // circle was deleted successfully :)
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Circle deleted successfully :)',
              style: 'success'
            });

            this.$store.dispatch('FETCH_USER');

            this.$router.push(`/circles`);
          }
        })
        .catch(error => {
          console.log('Error =>', error);
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Something went wrong, please try again',
            style: 'error'
          });
        })
        .finally(() => {
          this.$store.dispatch('TOGGLE_LOADER');
        });
    },
    async getMainColor(banner, remoteImage = true) {
      let bg_url =
        banner ||
        'https://i.ibb.co/s9YGcYP/edited-sharon-mccutcheon-TZZw-C-xs-Cl-Y-unsplash.jpg';

      let proxyUrl = 'https://proxy.mybubbl.me/';

      let result = 'primary';

      if (remoteImage) {
        result = await mainColor(proxyUrl + bg_url, {
          scale: 0.5,
          ignore: ignoreColors
        });
      } else {
        result = await mainColor(banner, {
          scale: 0.5,
          ignore: ignoreColors
        });
      }

      // var base64 = getBase64Image(document.getElementById("imageid"));
      this.styles.mainColor = result[0].color;
    },
    loadImgAsBase64(url, callback) {
      // let canvas = document.createElement('CANVAS');
      let img = new window.Image();
      img.setAttribute('crossorigin', 'anonymous');
      img.src = url;

      img.onload = () => {
        // canvas.height = img.height;
        // canvas.width = img.width;
        // let context = canvas.getContext('2d');
        // context.drawImage(img, 0, 0);
        // let dataURL = canvas.toDataURL('image/png');
        // canvas = null;
        // console.log('Image =>', img);
        callback(img);
      };
    },
    handleBanner(banner) {
      this.getMainColor(banner, false);
    }
  },
  mounted() {
    this.rules = this.circle.rules.split('\n').map(r => {
      return { text: r, emptyError: false };
    });
  }
};
</script>

<style scoped></style>
