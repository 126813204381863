<template>
  <div>
    <v-card-text>
      <p class="text-center caption">
        Began
        <span class="primary--text font-weight-black"> {{ started }} </span>
      </p>

      <h1 class="font-weight-bold subtitle-2">About</h1>
      <p class="body-2">{{ description }}</p>
      <h1 class="font-weight-bold subtitle-2">Rules</h1>
      <p class="body-2" style="white-space: pre-wrap">{{ rules }}</p>

      <h1 class="font-weight-bold subtitle-2">Moderators</h1>
      <template v-if="allMods.length > 0">
        <v-list>
          <v-list-item class="pa-0" v-for="(moderator, i) in allMods" :key="i">
            <b class="font-weight-bold mr-4">
              {{ i + 1 }}
            </b>

            <v-list-item-title>
              {{ moderator }}
              <v-icon
                small
                color="yellow darken-2"
                v-if="moderator == super_moderator"
              >
                {{ mdiCrown }}
              </v-icon>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-card-text>
  </div>
</template>

<script>
// icons
import { mdiCrown } from '@mdi/js';

export default {
  name: 'CircleDetails',
  data: () => ({
    mdiCrown
  }),
  props: {
    description: {
      type: String
    },
    rules: {
      type: String
    },
    regular_moderators: {
      type: Array
    },
    super_moderator: {
      type: String
    },
    date_created: {
      type: String
    }
  },
  computed: {
    allMods: function() {
      return [this.super_moderator, ...this.regular_moderators];
    },
    started: function() {
      let when = 'some time ago...';

      if (this.date_created) {
        let then = new Date(this.date_created);

        /** TODO: use Moment or something to properly format this... */
        when = then.toLocaleDateString();
      }

      return when;
    }
  }
};
</script>

<style scoped></style>
